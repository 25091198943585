import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 5×2\\@90% 1RM`}</p>
    <p>{`Weighted Hip Bridge 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 20 Back Squats (185/125)`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`20-Burpees Over Rower`}</p>
    <p>{`Cash Out: 20 Back Squats (185/125)`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts today!  The first workout will be release
live tonight at 8:00pm.  Register at: Games.CrossFit.com.  Each week the
Open wod will be Friday’s class wod so plan on attending on Friday in
order to be judged.  We’ll have our evening classes as our “Friday Night
Throwdown” from 4:00-7:00 rather than our traditional class
structure.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      